<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Multiselect from 'vue-multiselect'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueEditor, Quill } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize)

/**
 * Contents component
 */
export default {
  page: {
    title: 'business',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Multiselect,
    vueDropzone: vue2Dropzone,
    VueEditor,
    Loading,
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
      isLoading: false,
      baseImageUrl: BASE_IMAGE_URL,

      form: {
        status: true,
        title: '',
        address: '',
        since: '',
        descriptiontime: '',
        open_business: '',
        close_business: '',
        style: '',
        city: '',
        price: '',
        second_price: '',
        businessInfo: '',
        latitude: '',
        longitude: '',
        website: '',
        call: '',
        recommend: false,
        image: [],
        inputtag: [],
        lifestyle: [],
        lifestyle_options: [
          { item: { icon: 'food.png', name: 'food' }, name: 'food' },
          { item: { icon: 'shopping.png', name: 'shopping' }, name: 'shopping'},
          { item: { icon: 'thaitatto.png', name: 'thaitatto' }, name: 'thaitatto'},
          { item: { icon: 'attraction.png', name: 'attraction' }, name: 'attraction'},
          { item: { icon: 'hostel.png', name: 'hostel' }, name: 'hostel' },
          { item: { icon: 'message.png', name: 'message' }, name: 'message' },
          { item: { icon: 'heroscope.png', name: 'heroscope' },name: 'heroscope'},
          { item: { icon: 'restaurants.png', name: 'restaurants' }, name: 'restaurants'},
          { item: { icon: 'sacredplaces.png', name: 'sacredplaces' }, name: 'sacredplaces'},
          { item: { icon: 'sacreds.png', name: 'sacreds' }, name: 'sacreds'},
          { item: { icon: 'fortunetellers.png', name: 'fortunetellers' }, name: 'fortunetellers'},
          { item: { icon: 'fortune.png', name: 'fortune' }, name: 'fortune'},
          { item: { icon: 'thaimassage.png', name: 'thaimassage' }, name: 'thaimassage'},        
          { item: { icon: 'nightmarket.png', name: 'nightmarket' }, name: 'nightmarket'},
          { item: { icon: 'festivals.png', name: 'festivals' }, name: 'festivals'},
          { item: { icon: 'nightlife.png', name: 'nightlife' }, name: 'nightlife'},
          { item: { icon: 'cafe.png', name: 'cafe' }, name: 'cafe'},
          { item: { icon: 'accommodation.png', name: 'accommodation' }, name: 'accommodation'},
          { item: { icon: 'healthandbeauty.png', name: 'health & beauty' }, name: 'health & beauty' },

        ],
        amentities: [],
        amentities_options: [
          { item: { icon: 'wifi.png', name: 'wifi' }, name: 'wifi' },
          { item: { icon: 'parking.png', name: 'parking' }, name: 'parking' },
          {
            item: { icon: 'swimming_pool.png', name: 'swimming pool' },
            name: 'swimming pool',
          },
          {
            item: { icon: 'air_conditioning.png', name: 'air conditioning' },
            name: 'air conditioning',
          },
          { item: { icon: 'gym.png', name: 'gym' }, name: 'gym' },
          {
            item: { icon: 'airport_shuttle.png', name: 'airport shuttle' },
            name: 'airport shuttle',
          },
          {
            item: { icon: 'breakfast.png', name: 'breakfast' },
            name: 'breakfast',
          },
          {
            item: { icon: 'kids_area.png', name: 'kids area' },
            name: 'kids area',
          },
          { item: { icon: 'petfriendly.png', name: 'pet friendly' }, name: 'pet friendly',},
          { item: { icon: 'wheelchair_car.png', name: 'wheelchair accessible car park' }, name: 'wheelchair accessible car park',},
          { item: { icon: 'wheelchair_entrance.png', name: 'wheelchair accessible entrance' }, name: 'wheelchair accessible entrance',},
          { item: { icon: 'high_chair.png', name: 'high chair parking' }, name: 'high chair parking',},
          { item: { icon: 'outdoor_swimmingpool.png', name: 'outdoor swimming pool' }, name: 'outdoor swimming pool',},
          { item: { icon: 'indoor_swimmingpool.png', name: 'indoor swimming pool' }, name: 'indoor swimming pool',},
          { item: { icon: 'fitness.png', name: 'fitness' }, name: 'fitness',},

        ],
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 5,
        parallelUploads: 50,
        paramName: 'images',
        uploadMultiple: true,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      ismanuallyadd: false,
      status: {
        second_price: true,
      },
    }
  },
  validations: {
    form: {
      title: {
        required,
      },
      address: {
        required,
      },
      open_business: {
        required,
      },
      close_business: {
        required,
      },
      descriptiontime: {
        required,
      },
      style: {
        required,
      },
      city: {
        required,
      },
      price: {
        required,
      },
      businessInfo: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
      website: {
        required,
      },
      call: {
        required,
      },
      lifestyle: {
        required,
      },
    },
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    'form.price'(newValue) {
      if (Number(newValue) > 0) {
        this.status.second_price = false
      } else {
        this.status.second_price = true
        this.form.price = ''
        this.form.second_price = ''
      }
      // ...
    },
  },
  mounted() {},
  methods: {
    onTimeOpen(ctx) {
      console.log('o' + ctx)
      this.open_business = ctx
    },
    onTimeclose(ctx) {
      console.log('c' + ctx)

      this.close_business = ctx
    },
    doAjax() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },

    /*    vfileAdded(file) {
      let result = file.map((e => { return e }))
      this.form.image.push(result)

      console.log("log img" + this.form.image)

     // this.form.image = file
      this.ismanuallyadd = false
    }, */
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    async formSubmit() {
      if (!this.form.image) {
        this.$toast.error('Image field must not be empty ')
      }

      this.doAjax()

      let body = {
        title: this.form.title,
        address: this.form.address,
        since: this.form.since,
        open: this.form.open_business,
        close: this.form.close_business,
        descriptiontime: this.form.descriptiontime,
        style: this.form.style,
        city: this.form.city,
        price: this.form.price,
        second_price: this.form.second_price,
        lifestyle: this.form.lifestyle,
        amentities: this.form.amentities,
        businessInfo: this.form.businessInfo,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        website: this.form.website,
        call: this.form.call,
        recommend: this.form.recommend,

        // content_text: this.form.content_text,
      }
      const data = new FormData()

      if (!this.ismanuallyadd) {
        for (const i of Object.keys(this.form.image)) {
          data.append('image', this.form.image[i])
        }
      }

      data.append('data', JSON.stringify(body))

      const queryId = this.$route.query.id
      const request = axios.post('/api/business', data)

      await request
        .then(async (result) => {
          this.$toast.success('Saved successfully')
          this.$router.back()
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.error(err.response.data.error.message)
        })
    },

    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('image', file)

      await axios
        .post('api/content/ckupload_images', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((result) => {
          let url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">business</h4>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        v-model="$v.form.title.$model"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        :state="validateState('title')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Address</label>
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        placeholder="Enter address "
                        name="address"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
 
                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Open:</label>
                        <b-form-timepicker
                          v-model="form.open_business"
                          locale="en"
                        ></b-form-timepicker>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Close:</label>
                        <b-form-timepicker
                          v-model="form.close_business"
                          locale="en"
                        ></b-form-timepicker>
                      </b-col>
                    </b-row>

                    <b-form-group label-cols-lg="0">
                      <label>Description Time</label>
                      <b-form-input
                        id="descriptiontime"
                        v-model="form.descriptiontime"
                        placeholder="Enter description time "
                        name="descriptiontime"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Business Info</label>
                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Open:</label>
                        <b-form-timepicker v-model="form.open_business" locale="en"></b-form-timepicker>
                      </b-col>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Close:</label>
                        <b-form-timepicker v-model="form.close_business" locale="en"></b-form-timepicker>
                      </b-col>
                    </b-row>

                    <!-- <b-form-group label-cols-lg="0">
                      <label>Short description</label>
>>>>>>> app/admin/src/router/views/pages/business/create.vue
                      <b-form-input
                        id="descriptiontime"
                        v-model="form.businessInfo"
                        placeholder="Enter Business Info "
                        name="descriptiontime"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>
                        Image
                        <small class="color-text-small"
                          >Width 500 x Height 500. Just only one.</small
                        >
                      </label>
                            <vue-dropzone
                        id="form-image"
                        ref="myVueDropzone"
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :state="validateState('image')"
                        aria-describedby="input-3-live-feedback"
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                      ></vue-dropzone>
                      <b-form-invalid-feedback id="input-3-live-feedback"
                        >This is a required field</b-form-invalid-feedback
                      > -->
                      <vue-dropzone
                        ref="myVueDropzone"
                        id="form-image"
                        accepted-files="image/*"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        @vdropzone-success-multiple="vfileAdded"
                      ></vue-dropzone>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label>Since</label>
                        <b-form-input
                          id="since"
                          v-model="form.since"
                          placeholder="Enter since "
                          name="since"
                          type="number"
                          :min="0"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6" class="mb-3">
                        <label>Business Style</label>
                        <b-form-input
                          id="style"
                          v-model="form.style"
                          placeholder="Enter style "
                          name="style"
                          type="text"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-form-group label-cols-lg="0">
                      <label>City</label>
                      <b-form-input
                        id="city"
                        v-model="form.city"
                        placeholder="Enter city "
                        name="city"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-row>
                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Price:</label>
                        <b-form-input
                          id="price"
                          v-model="form.price"
                          placeholder="Enter price "
                          name="price"
                          type="number"
                          :min="0"
                        ></b-form-input>
                      </b-col>

                      <b-col cols="6" class="mb-3">
                        <label class="mr-4">Second Price:</label>
                        <b-form-input
                          id="second_price"
                          v-model="form.second_price"
                          placeholder="Enter second price "
                          name="second_price"
                          type="number"
                          :min="0"
                        ></b-form-input>
                      </b-col>
                    </b-row>

                    <b-form-group label-cols-lg="0">
                      <label>Latitude</label>
                      <b-form-input
                        id="latitude"
                        v-model="form.latitude"
                        placeholder="Enter latitude "
                        name="latitude"
                        type="number"
                        step="any"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Longitude</label>
                      <b-form-input
                        id="longitude"
                        v-model="form.longitude"
                        placeholder="Enter longitude "
                        name="longitude"
                        type="number"
                        step="any"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Phone</label>
                      <b-form-input
                        id="call"
                        v-model="form.call"
                        placeholder="Enter Phone"
                        name="call"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-lg="0">
                      <label>Website</label>
                      <b-form-input
                        id="website"
                        v-model="form.website"
                        placeholder="Enter website "
                        name="website"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label></label>
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.recommend"
                        name="checkbox-1"
                        true-value="true"
                        false-value="false"
                      >
                        recommend business
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- lifestyle option -->
                    <div>
                      <b-form-group label-cols-lg="0">
                        <label>Lifestyle</label>
                        <b-form-checkbox-group
                          v-model="form.lifestyle"
                          :options="form.lifestyle_options"
                          class="mb-3"
                          value-field="item"
                          text-field="name"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </div>
                    <!-- amentities option -->
                    <div>
                      <b-form-group label-cols-lg="0">
                        <label>Amentities</label>
                        <b-form-checkbox-group
                          v-model="form.amentities"
                          :options="form.amentities_options"
                          class="mb-3"
                          value-field="item"
                          text-field="name"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </div>

                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>

                    <b-form-group>
                      <router-link to="/business">
                        <button
                          type="button"
                          class="btn btn-danger float-right ml-2"
                          >Cancel</button
                        >
                      </router-link>
                      <button type="submit" class="btn btn-primary float-right"
                        >Save</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scope>
.unactiveClass {
  border: 1px solid #5269f785;
  background: #5269f785;
}
</style>
